<template>
  <div class="category">
    <hcc-table
      :title="$t('configuration.categories.title')"
      :initialPage="1"
      :columns="columns"
      :rows="categoriesList"
      :group-options="{ enabled: true, collapsable: true }"
      @edit="edit"
      @delete="deleteOption"
      @add="openAddCategory"
      @addSubgroup="addSubCategory"
      @editSubgroup="editSubCategory"
      :pagination="false"
      class="left-align"
    >
      <template slot="table-row" slot-scope="props">
        <hcc-input
          v-focus
          v-if="props.column.field == 'title' && props.row.editable"
          v-model.trim="props.row.name"
          @keyup.enter="editSubCategory(props.row)"
        />
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <hcc-table
      :title="$t('configuration.categories.global-title')"
      :initialPage="1"
      :columns="columns"
      :rows="globalSubcategory"
      @edit="editGlobal"
      @delete="deleteOption"
      :pagination="false"
      class="left-align "
    />
    <transition name="fade">
      <hcc-modal
        :clickToClose="false"
        name="category-modal"
        :title="title"
        @confirm="updateCategories"
        @cancel="cancel"
      >
        <template v-if="!isEdit">
          <hcc-checkbox
            :label="this.$t('configuration.categories.header')"
            :name="this.$t('configuration.categories.header')"
            @change="handleCheck({ enabler: 'general', disable: 'global'}, $event)"
            :value="general"
          />
          <hcc-checkbox
            :label="this.$t('configuration.categories.global')"
            name="Subcategoria"
            @change="handleCheck({ enabler: 'global', disable: 'general'}, $event)"
            :value="global"
          />
        </template>
        <div class="form-modal">
          <hcc-input
            class="modal-input"
            v-focuseModal
            :label="$t('common.name')"
            v-model.trim="newCategory.title"
            @keyup.enter="submit('category')"
          />
        </div>
      </hcc-modal>
    </transition>
    <transition name="fade">
      <hcc-modal
        :clickToClose="false"
        name="subcategory-modal"
        :title="$t('configuration.categories.sub-category')"
        @confirm="updateSubCategories"
        @cancel="cancel"
      >
        <div class="form-modal">
          <hcc-input
            class="modal-input"
            v-focuseModal
            :label="$t('common.name')"
            v-model.trim="options.subCat"
            @keyup.enter="submit('subcategory')"
          />
        </div>
      </hcc-modal>
    </transition>
    <transition name="fade">
      <error-modal :message="errorMessage" @error="handleErrorConfirm" />
    </transition>
    <transition name="fade">
      <hcc-confirmation />
    </transition>
  </div>
</template>

<script>
import EventBus from '@/eventBus';
import {
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_ERROR,
} from '@/eventTypes';
import ErrorHandler from '@/utils/error.handler';

export default {
  props: {
    campaign: {
      required: true,
    },
  },
  components: {
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    ErrorModal: () => import('@/components/ErrorModal.vue'),
    HccCheckbox: () => import('@/components/shared/HccCheckbox/index.vue'),
  },
  directives: {
    focus: {
      inserted(el) {
        el.children[0].children[0].focus();
      },
    },
  },
  data() {
    return {
      isEdit: false,
      general: true,
      global: false,
      errorMessage: '',
      delete: {},
      categories: [],
      options: {},
      newCategory: { title: '' },
      categoriesList: [],
      globalSubcategory: [],
    };
  },
  watch: {
    campaign(newVal) {
      if (newVal.categories) {
        this.categories = newVal.categories;
        this.updateCategoriesList();
      } else {
        this.categories = [];
      }
    },
  },
  computed: {
    columns() {
      return [{
        label: this.$t('common.name'),
        field: 'title',
        width: '85%',
      }];
    },
    title() {
      return this.global ? this.$t('configuration.categories.global') : this.$t('configuration.categories.header');
    },
    categoryType() {
      return this.global ? 'Subcategory' : 'Category';
    },
  },
  created() {
    EventBus.$on(UPDATE_CAMPAIGN_ERROR, (err) => {
      ErrorHandler.logErrors(err);
    });
  },
  mounted() {
    this.categories = this.campaign.categories;
    this.updateCategoriesList();
    this.$emit('changed', false);
  },
  destroyed() {
    EventBus.$off(UPDATE_CAMPAIGN_ERROR);
  },
  methods: {
    updateCategoriesList() {
      this.cancel();
      this.globalSubcategory = this.campaign?.globalSubcategory;
      const list = [];
      if (this.categories && this.categories.length > 0) {
        this.categories.forEach((c, i) => {
          const children = this.childrenCategories(c);
          list.push({
            ...c,
            index: i,
            children,
          });
        });
      }
      this.categoriesList = list;
    },
    handleErrorConfirm() {
      this.cancel();
    },
    childrenCategories(cat) {
      return cat.subcategories.map((c, index) => ({
        ...c,
        index,
        name: c.title,
        editable: false,
      }));
    },
    edit(props) {
      this.isEdit = true;
      this.newCategory = props;
      this.$modal.show('category-modal');
    },
    updateCategories() {
      const { title, id } = this.newCategory;
      const valid = this.categoryType === 'Category'
        ? this.validCategory(title)
        : this.validGlobal(title);
      if (!valid) {
        this.saveCategories({ id, title, type: this.categoryType });
      } else {
        this.error(this.$t('configuration.error-exists'));
      }
    },
    updateSubCategories() {
      const { subCat, id } = this.options;
      if (!this.validSubCategory(subCat, id)) {
        this.saveCategories({ title: subCat, parent: id });
      } else {
        this.error(this.$t('configuration.error-exists'));
      }
    },
    cancel() {
      this.options = {};
      this.newCategory = { title: '' };
      this.delete = {};
      this.errorMessage = '';
      this.isEdit = false;
      this.handleCheck({ enabler: 'general', disable: 'global' }, true);
    },
    deleteOption(props) {
      if (this.categoryMsg(props.id)) {
        this.error(this.$t('configuration.categories.delete-error'));
      } else {
        this.$modal.show('confirmation', {
          title: `Delete "${props.title}"`,
          description: this.$t('configuration.confirmation'),
          variant: 'error',
          confirm: () => this.saveCategories({
            id: props.id,
            parent: props.parent,
            remove: true,
          }),
        });
      }
    },
    categoryMsg(id) {
      return this.campaign.messages.find(c => c.value === id);
    },
    openAddCategory() {
      this.$modal.show('category-modal');
    },
    addSubCategory(category) {
      this.options = { ...category, subCat: '' };
      this.$modal.show('subcategory-modal');
    },
    getCategory(id) {
      return this.categoriesList.find(c => c.id === id);
    },
    saveCategories(category) {
      const data = {
        id: this.campaign.id,
        categories: category,
      };
      EventBus.$emit(UPDATE_CAMPAIGN, { data, tab: 'Category' });
    },
    validSubCategory(value, id) {
      const category = this.getCategory(id);
      return category.children.some(c => c.title === value);
    },
    validCategory(name) {
      return this.categoriesList.some(c => c.title === name);
    },
    validGlobal(name) {
      return this.globalSubcategory.some(c => c.title === name);
    },
    editSubCategory(params) {
      const parent = this.getCategory(params.parent);
      this.$set(parent, params.index, { params, editable: false });
      if (params.name !== params.title) {
        if (!this.validSubCategory(params.name, params.parent)) {
          this.saveCategories({
            title: params.name,
            parent: params.parent,
            id: params.id,
          });
        } else {
          this.error(this.$t('configuration.error-exists'));
        }
      }
    },
    error(msg) {
      this.errorMessage = msg;
      this.$modal.show('error-modal');
    },
    submit(id) {
      if (id === 'category' && this.newCategory.name.length > 0) {
        this.updateCategories();
        this.$modal.hide('category-modal');
      } else if (id === 'subcategory' && this.options.subCat.length > 0) {
        this.updateSubCategories();
        this.$modal.hide('subcategory-modal');
      }
    },
    handleCheck({ enabler, disable }, e) {
      this[enabler] = e;
      this[disable] = !e;
    },
    editGlobal(props) {
      this.newCategory = props;
      this.isEdit = true;
      this.handleCheck({ enabler: 'global', disable: 'general' }, true);
      this.$modal.show('category-modal');
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/settings/shared/_modal.scss";

::v-deep .left-align {
  margin-bottom: 20px;
  table.vgt-table {
    .vgt-left-align {
      text-align: left;
    }
  }
}

::v-deep .checkbox-container {
  margin: 5px 15px 15px 4px;
}
</style>
